import { defaultStyles } from '@/helper/defaultStyles';

function InfoItem(props: { title: string; imgUrl: string; desc: string }) {
  return (
    <div className="flex h-[80%] flex-col gap-4 items-center justify-center p-8 border border-solid border-[#E7E7E7]">
      <img
        loading="lazy"
        src={props.imgUrl}
        className="h-[74px] w-[74px]"
        alt="icon"
      />
      {/* <div className="text-[24px] font-bold">{props.title}</div> */}
      <div>{props.desc}</div>
    </div>
  );
}

function InfoGroup() {
  return (
    <div
      className={`${defaultStyles.paddingBig} py-24 flex flex-col items-center justify-center gap-8 bg-default text-center`}
    >
      {/* <div className="font-bold text-[35px] uppercase">
        Witaj na <span className="text-primary">"Nazwa Strony"</span>
      </div>
      <div className="h-1 w-24 bg-primary" /> */}
      {/* <div className="w-3/4">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sodales
        est in gravida dapibus. Ut erat lorem, maximus vitae augue id, pulvinar
        elementum arcu.
      </div> */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 justify-center items-start">
        <InfoItem
          title="Lorem ipsum"
          desc="Śledź każdy krok Twojej paczki w czasie rzeczywistym, bez komplikacji i niejasności."
          imgUrl="/icon/welcome/plane.svg"
        />
        <InfoItem
          title="Lorem ipsum"
          desc="Nasza platforma dostarcza szybkie i przejrzyste informacje o statusie Twoich przesyłek."
          imgUrl="/icon/welcome/ship.svg"
        />
        <InfoItem
          title="Lorem ipsum"
          desc="Dbamy o Twój spokój ducha, ciągle udoskonalając naszą intuicyjną usługę śledzenia."
          imgUrl="/icon/welcome/van.svg"
        />
      </div>
    </div>
  );
}

export default InfoGroup;
