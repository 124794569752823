import InfoGroup from '@/components/InfoGroup';
import SearchForPackageSection from '@/components/SearchForPackageSection';
import ServicesSection from '@/components/ServicesSection';
import { defaultStyles } from '@/helper/defaultStyles';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { FunctionComponent } from 'react';
import { useMediaQuery } from 'react-responsive';
import YouTube from 'react-youtube';

const Home: FunctionComponent = () => {
  const router = useRouter();
  const { awb } = router.query;

  const awbParam = Array.isArray(awb) ? awb[0] : awb;

  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const opts = {
    height: !isDesktop ? '195' : '440',
    width: !isDesktop ? '320' : '722',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div
      className={`bg-white overflow-hidden flex flex-col justify-center items-center`}
    >
      <Head>
        <title>Nowe kolory</title>
        <meta name="description" content="Strona główna firmy Nowe kolory" />
      </Head>
      <SearchForPackageSection param={awbParam} />
      <InfoGroup />
      <div
        className={`${defaultStyles.paddingBig} flex flex-col gap-8 justify-center items-center py-24`}
      >
        <div className="text-[40px] font-bold">Poznaj naszą firmę</div>
        <YouTube videoId="W0NBFLgzbII" opts={opts} />
      </div>
      <ServicesSection />
    </div>
  );
};

export default Home;
