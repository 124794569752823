import { defaultStyles } from '@/helper/defaultStyles';

function ServiceItem(props: { title: string; desc: string; imgUrl: string }) {
  return (
    <div className="flex flex-row items-center gap-8 p-8 border border-solid border-[#232C64]">
      <img
        loading="lazy"
        src={props.imgUrl}
        className="h-[74px] w-[74px]"
        alt="icon"
      />
      <div className="flex flex-col gap-4">
        {/* <div className="text-[25px] font-bold text-default">{props.title}</div> */}
        <div className="text-default">{props.desc}</div>
      </div>
    </div>
  );
}

function ServicesSection() {
  return (
    <div
      className={`${defaultStyles.paddingBig} py-24 bg-tertiary flex flex-col items-center gap-8 justify-center`}
    >
      <div className="flex flex-col xl:flex-row gap-8 justify-between items-start xl:items-center">
        <div className="text-default uppercase xl:w-1/3 text-[25px] font-bold">
          O nas
        </div>
        <div className="flex flex-row gap-4">
          <div className="w-1 h-24 bg-primary" />
          <div className="text-default">
            W "Nowe Kolory" łączymy naszą pasję do e-commerce z technologiczną
            innowacyjnością, aby zapewnić Ci nie tylko narzędzie do efektywnego
            śledzenia przesyłek, ale również spokój ducha wiedząc, że Twoje
            paczki są zawsze tam, gdzie powinny być. Nasze wieloletnie
            doświadczenie i ciągłe dążenie do doskonałości czynią nas liderem w
            branży, a indywidualne podejście do każdego klienta sprawia, że
            możesz liczyć na nasze wsparcie na każdym etapie współpracy.
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
        <ServiceItem
          title="Lorem ipsum"
          desc="Rozpoczęliśmy z myślą o ułatwieniu polskim sprzedawcom podbijania zagranicznych rynków."
          imgUrl="/icon/services/package.svg"
        />
        <ServiceItem
          title="Lorem ipsum"
          desc="Nasze doświadczenie obejmuje rynki od Rumunii po Cypr, co pozwala dostosować usługi do Twoich potrzeb."
          imgUrl="/icon/services/boat.svg"
        />
        <ServiceItem
          title="Lorem ipsum"
          desc="Każdy klient jest dla nas ważny – gwarantujemy indywidualne podejście."
          imgUrl="/icon/services/global.svg"
        />
        <ServiceItem
          title="Lorem ipsum"
          desc="Innowacje są naszą pasją, więc zawsze dostarczamy nowoczesne rozwiązania w logistyce."
          imgUrl="/icon/services/garage.svg"
        />
        <ServiceItem
          title="Lorem ipsum"
          desc="Nasz zespół to grupa ludzi z pasją, którzy nieustannie dążą do doskonałości."
          imgUrl="/icon/services/door.svg"
        />
        <ServiceItem
          title="Lorem ipsum"
          desc="Jesteśmy Twoim zaufanym partnerem w zarządzaniu przesyłkami, niezależnie od skali działalności."
          imgUrl="/icon/services/truck.svg"
        />
      </div>
    </div>
  );
}

export default ServicesSection;
